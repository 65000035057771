import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useCache } from "../CacheProvider";

const backendUrl = process.env.REACT_APP_API_URL;

export default function CreateUser({ open, onClose }) {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [roles, setRoles] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    role: "",
    team: "",
  });
  const { get, set, remove } = useCache();

  const fetchRoles = useCallback(async () => {
    const cachedData = get("roles");
    if (cachedData) {
      setRoles(cachedData);
      return;
    }
    try {
      const response = await axios.get(`${backendUrl}/roles/`);
      setRoles(response.data);
      set("roles", response.data);
    } catch (error) {
      console.error("Failed to fetch roles:", error);
    }
  }, [get, set]);

  const fetchTeams = useCallback(async () => {
    const cachedData = get("teams");
    if (cachedData) {
      setTeams(cachedData);
      return;
    }
    try {
      const response = await axios.get(`${backendUrl}/teams/`);
      setTeams(response.data);
      set("teams", response.data);
    } catch (error) {
      console.error("Failed to fetch teams:", error);
    }
  }, [get, set]);

  useEffect(() => {
    fetchRoles();
    fetchTeams();
  }, [fetchRoles, fetchTeams]);

  const clearForm = () => {
    setUsername("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setFirstName("");
    setLastName("");
    setSelectedRole(null);
    setSelectedTeam(null);
    setFieldErrors({
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      role: "",
      team: "",
    });
  };

  const handleFieldChange = (field, value) => {
    // Dynamically update the field value and clear the corresponding error
    switch (field) {
      case "username":
        setUsername(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        break;
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "role":
        setSelectedRole(value);
        break;
      case "team":
        setSelectedTeam(value);
        break;
      default:
        break;
    }

    // Clear the error for the field
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!username) errors.username = "Kenttä vaaditaan";
    if (!email) errors.email = "Kenttä vaaditaan";
    else if (!emailRegex.test(email))
      errors.email = "Sähköposti ei ole kelvollinen";
    if (!password) errors.password = "Kenttä vaaditaan";
    else if (password.length < 6)
      errors.password = "Salasanan pitää olla vähintään 6 merkkiä";
    if (!confirmPassword) errors.confirmPassword = "Kenttä vaaditaan";
    else if (confirmPassword !== password)
      errors.confirmPassword = "Salasanat eivät täsmää";
    if (!firstName) errors.firstName = "Kenttä vaaditaan";
    if (!lastName) errors.lastName = "Kenttä vaaditaan";
    if (!selectedRole) errors.role = "Kenttä vaaditaan";

    if (selectedRole?.name !== "admin" && !selectedTeam) {
      errors.team = "Kenttä vaaditaan";
    }

    if (Object.keys(errors).length) {
      setFieldErrors(errors);
      return;
    }

    const userData = {
      username,
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      role_list_id: selectedRole ? selectedRole.id : undefined,
      team_id: selectedTeam ? selectedTeam.id : undefined,
    };

    axios
      .post(`${backendUrl}/users/`, userData)
      .then((response) => {
        console.log(response.data);
        remove("users"); // Remove the cached users to force a refresh
        onClose(); // Close the dialog
        clearForm(); // Reset form fields
      })
      .catch((error) => {
        console.error("Error creating user:", error);
      });
  };

  const handleClose = () => {
    onClose();
    clearForm();
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Luo uusi käyttäjä</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Autocomplete
              id="role-select"
              options={roles}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Valitse rooli"
                  error={Boolean(fieldErrors.role)}
                  helperText={fieldErrors.role && "Kenttä vaaditaan"}
                  variant="standard"
                />
              )}
              value={selectedRole}
              onChange={(event, newValue) => {
                handleFieldChange("role", newValue);
              }}
            />
          </Box>
          {selectedRole?.name !== "admin" && (
            <Box mb={2}>
              <Autocomplete
                id="team-select"
                options={teams}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Valitse tiimi"
                    error={Boolean(fieldErrors.team)}
                    helperText={fieldErrors.team && "Kenttä vaaditaan"}
                    variant="standard"
                  />
                )}
                value={selectedTeam}
                onChange={(event, newValue) => {
                  handleFieldChange("team", newValue);
                }}
              />
            </Box>
          )}
          <Box mb={2}>
            <TextField
              required
              id="firstName"
              label="Etunimi"
              value={firstName}
              fullWidth
              onChange={(e) => handleFieldChange("firstName", e.target.value)}
              error={Boolean(fieldErrors.firstName)}
              helperText={fieldErrors.firstName}
              variant="standard"
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              id="lastName"
              label="Sukunimi"
              value={lastName}
              fullWidth
              onChange={(e) => handleFieldChange("lastName", e.target.value)}
              error={Boolean(fieldErrors.lastName)}
              helperText={fieldErrors.lastName}
              variant="standard"
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              id="username"
              label="Käyttäjänimi"
              value={username}
              fullWidth
              onChange={(e) => handleFieldChange("username", e.target.value)}
              error={Boolean(fieldErrors.username)}
              helperText={fieldErrors.username}
              variant="standard"
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              id="email"
              label="Sähköposti"
              value={email}
              fullWidth
              onChange={(e) => handleFieldChange("email", e.target.value)}
              error={Boolean(fieldErrors.email)}
              helperText={fieldErrors.email}
              variant="standard"
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              id="password"
              label="Salasana"
              value={password}
              fullWidth
              type="password"
              onChange={(e) => handleFieldChange("password", e.target.value)}
              error={Boolean(fieldErrors.password)}
              helperText={fieldErrors.password}
              variant="standard"
            />
          </Box>
          <Box mb={2}>
            <TextField
              required
              id="confirmPassword"
              label="Vahvista salasana"
              value={confirmPassword}
              fullWidth
              type="password"
              onChange={(e) =>
                handleFieldChange("confirmPassword", e.target.value)
              }
              error={Boolean(fieldErrors.confirmPassword)}
              helperText={fieldErrors.confirmPassword}
              variant="standard"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Peruuta</Button>
          <Button onClick={handleSubmit}>Tallenna</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
