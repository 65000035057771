import React, { useState, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function CustomTabPanel({ children, value, index }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ p: { sx: 0, sm: 0, md: 3 } }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function AsoyNav({ tabs }) {
  const [value, setValue] = useState(0);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, []);

  // Memoize the tab buttons to prevent unnecessary re-renders
  const tabButtons = useMemo(() => 
    tabs.map((tab, index) => (
      <Tab
        key={index}
        label={tab.label}
        {...a11yProps(index)}
        sx={{
          '&:hover': {
            color: '#F8F2ED',
          },
        }}
      />
    )), [tabs]);

  // Memoize the tab panels to prevent unnecessary re-renders
  const tabPanels = useMemo(() => 
    tabs.map((tab, index) => (
      <CustomTabPanel key={index} value={value} index={index}>
        {tab.panel}
      </CustomTabPanel>
    )), [tabs, value]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          {tabButtons}
        </Tabs>
      </Box>
      {tabPanels}
    </Box>
  );
}

AsoyNav.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      panel: PropTypes.node.isRequired,
    })
  ).isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default React.memo(AsoyNav);
