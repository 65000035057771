// KPTSDialog.js
import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField, 
  Button 
} from '@mui/material';

const KPTSDialog = ({ open, onClose, onGenerate }) => {
  const [extraInfo, setExtraInfo] = useState('');

  const handleGenerate = () => {
    onGenerate(extraInfo);
    setExtraInfo('');
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Lisätiedot KPTS:ään</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Lisätiedot"
          type="text"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={extraInfo}
          onChange={(e) => setExtraInfo(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Peruuta</Button>
        <Button onClick={handleGenerate}>Generoi KPTS</Button>
      </DialogActions>
    </Dialog>
  );
};

export default KPTSDialog;
