import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Typography, CircularProgress } from "@mui/material";
import CustomTable from "../styles/CustomTable.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/Authentication.js";
import CustomPieChart from "../utils/CustomPieChart.js";
import { useCache } from "../CacheProvider.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../styles/theme.js";

const backendUrl = process.env.REACT_APP_API_URL;

const ElementsTableAll = () => {
  const [rows, setRows] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { token } = useAuth();
  const { get, set } = useCache();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const isAuthenticated = Boolean(token);

  useEffect(() => {
    if (!isAuthenticated || !token) {
      setLoading(false);
      return;
    }

    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        if (!isAuthenticated || !token) return;

        const cacheTimestamp = get("lifecycle_end_timestamp");
        const cacheData = get("lifecycle_end");

        // Use cache if data is recent (valid for 5 minutes)
        const isCacheValid = cacheTimestamp && Date.now() - cacheTimestamp < 5 * 60 * 1000;
        if (isCacheValid && cacheData) {
          handleData(cacheData);
          setLoading(false);
          return;
        }

        setLoading(true); // Show loading spinner during fetch

        const response = await axios.get(`${backendUrl}/lifecycle-end/`, {
          headers: { Authorization: `Bearer ${token}` },
          signal: abortController.signal,
          timeout: 10000,
        });

        // Ensure data is in expected format
        if (!Array.isArray(response.data)) {
          throw new Error("Invalid response format from the server.");
        }

        handleData(response.data);
        set("lifecycle_end", response.data);
        set("lifecycle_end_timestamp", Date.now());
      } catch (err) {
        console.error("Error fetching element data:", err);
        setRows([]);
        setPieChartData([]);
      } finally {
        setLoading(false);
      }
    };

    // Initial fetch
    fetchData();

    return () => {
      abortController.abort();
    };
  }, [isAuthenticated, token, get, set]);

  const handleData = (dataArray) => {
    let transformedRows = [];
    try {
      transformedRows = dataArray
        .filter((item) => item && item.asoyId !== null)
        .map((item) => ({
          element: item.element,
          yearsLeft: parseInt(item.yearsLeft),
          asoy: item.asoyName,
          elementId: item.elementId,
          asoyId: item.asoyId,
        }));

      setRows(transformedRows);
    } catch (error) {
      console.error("Error transforming data:", error);
      setRows([]); // Empty rows if transformation fails
    }

    // Pie chart data processing - group by asoy
    const asoyCounts = transformedRows.reduce((counts, item) => {
      if (!counts[item.asoy]) {
        counts[item.asoy] = 0;
      }
      counts[item.asoy]++;
      return counts;
    }, {});

    // Generate consistent colors for asoys
    const baseColors = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"];
    const colors = Object.keys(asoyCounts).map((_, index) => baseColors[index % baseColors.length]);

    const pieData = Object.entries(asoyCounts)
      .sort(([, a], [, b]) => b - a) // Sort by count in descending order
      .slice(0, 6) // Take top 6 asoys
      .map(([label, value], index) => ({
        id: label,
        label: `${label}`,
        value,
        color: colors[index],
      }));

    setPieChartData(pieData);
  };

  const handleRowClick = (row) => {
    if (row && row.asoyId) {
      navigate(`/raportit/${row.asoyId}/`);
    }
  };

  const getYearsLeftColor = (years) => {
    const yearsNum = parseFloat(years);
    if (yearsNum <= 1) return "#ff0000";
    if (yearsNum <= 2) return "#ff4500";
    if (yearsNum <= 3) return "#ffa500";
    if (yearsNum <= 4) return "#ffd700";
    return "#ffff00";
  };

  const columns = [
    {
      id: "element",
      label: "Rakenneosa",
      style: { whiteSpace: "normal", wordWrap: "break-word", minWidth: "300px" },
    },
    {
      id: "yearsLeft",
      label: "Elinkaarta jäljellä",
      style: (value) => ({
        color: getYearsLeftColor(value),
        fontWeight: "bold",
      }),
    },
    {
      id: "asoy",
      label: "Asoy",
      style: { minWidth: "150px" },
    },
  ];

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="h5" sx={{ marginLeft: isSmallScreen ? "0px" : "20px", mb: 1 }}>
        Taloyhtiöt, joilla on eniten elinkaaren lopussa olevia rakenneosia
      </Typography>

      {pieChartData.length > 0 && (
        <Box sx={{ mt: 4, mb: 3 }}> 
          <CustomPieChart
            data={pieChartData}
            navigate={navigate}
            legendOptions={{
              position: "right",
              labels: {
                fontSize: "1rem",
                marginLeft: "60px"
              }
            }}
          />
        </Box>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          marginLeft: isSmallScreen ? "-60px" : "0px",
          width: isSmallScreen ? "100vw" : "auto",
        }}
      >
        <CustomTable
          columns={columns}
          rows={rows} // Empty rows will render a blank table
          onRowClick={handleRowClick}
          hoverBackgroundColor={"#f5f5f5"}
        />
      </Box>
    </Box>
  );
};

export default ElementsTableAll;
