import axios from "axios";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import logo from "../icons/kontu3.png";
import theme from "../styles/theme";

const backendUrl = process.env.REACT_APP_API_URL;

// Utility function to format dates to Finnish format
const formatDateToFinnish = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  if (isNaN(date)) return ""; // Handle invalid dates
  return new Intl.DateTimeFormat("fi-FI").format(date);
};

export const generateKPTS = async (asoyId, token, extraInfo = "") => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    console.log("Fetching data for KPTS generation...");
    console.log(`Asoy ID: ${asoyId}`);
    console.log(`Backend URL: ${backendUrl}`);

    const [
      asoyResponse,
      historyResponse,
      recurringHistoryResponse,
      upcomingMaintenanceResponse,
    ] = await Promise.all([
      axios.get(`${backendUrl}/asoy/${asoyId}/`, config),
      axios.get(`${backendUrl}/asoy/${asoyId}/maintenance-history/`, config),
      axios.get(
        `${backendUrl}/asoy/${asoyId}/maintenance/recurring/history/last-year`,
        config
      ),
      axios.get(
        `${backendUrl}/asoy/${asoyId}/maintenance/recurring/next5years`,
        config
      ),
    ]);

    let plansData = [];
    try {
      const plansResponse = await axios.get(
        `${backendUrl}/asoy/${asoyId}/plans/`,
        config
      );
      plansData = plansResponse.data || [];
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log("No plans found for this asoy. Continuing with empty plans data.");
      } else {
        throw error;
      }
    }

    console.log("Data fetched successfully");

    const asoyDetails = asoyResponse.data;
    const historyData = historyResponse.data || [];
    const recurringHistoryData = recurringHistoryResponse.data || [];
    const upcomingMaintenanceData = upcomingMaintenanceResponse.data || [];

    const doc = new jsPDF();

    // Add logo
    const img = new Image();
    img.src = logo;
    await new Promise((resolve) => {
      img.onload = resolve;
    });
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = img.width / 8;
    canvas.height = img.height / 8;
    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    const resizedImage = canvas.toDataURL("image/png");
    doc.addImage(resizedImage, "PNG", 165, 2, 38, 14);

    // Add headers and introductory text
    doc.setFontSize(20);
    doc.text(
      "ASUNTO-OSAKEYHTIÖN HALLITUKSEN \nTEETTÄMÄ KUNNOSSAPITOTARVESELVITYS",
      15,
      15
    );
    doc.setFontSize(12);
    doc.text(`Yhtiö: ${asoyDetails.name}`, 15, 35);
    doc.text(`Y-tunnus: ${asoyDetails.business_id}`, 15, 42);
    doc.text(`Osoite: ${asoyDetails.address_road}`, 15, 49);
    doc.text(`Päiväys: ${new Date().toLocaleDateString("fi-FI")}`, 15, 56);

    let yPosition = 110;

    doc.setFontSize(10);
    doc.text(
      "Asunto-osakeyhtiölain (1599/2009) mukaan asunto-osakeyhtiön hallituksen on esitettävä vuosittain varsinaisessa \nyhtiökokouksessa kirjallinen selvitys tarpeesta sellaiseen yhtiön rakennusten ja kiinteistöjen kunnossapitoon \nyhtiökokousta seuraavien viiden vuoden aikana, joka vaikuttaa olennaisesti osakehuoneiston käyttämiseen, \nyhtiövastikkeeseen tai muihin osakehuoneiston käytöstä aiheutuviin kustannuksin.",
      15,
      70
    );
    doc.text(
      "Yhtiökokous merkitsee hallituksen selvityksen tiedoksi. Käynnistettävien toimenpiteiden suunnittelusta, toteutuksesta \nja rahoituksesta päätetään yhtiökokouksessa erikseen.",
      15,
      95
    );

    // Custom table style for all tables
    const tableStyles = {
      headStyles: {
        fillColor: theme.palette.primary.main, // Warm beige
        textColor: theme.palette.primary.contrastText, // Black text
        fontStyle: "bold",
      },
      bodyStyles: {
        fillColor: theme.palette.action.hover, // Soft cream
      },
      alternateRowStyles: {
        fillColor: "#FFFFFF", // White for alternate rows
      },
    };
    // Plans Table
    if (plansData.length > 0) {
      doc.setFontSize(16);
      doc.text("Suunnitelmat", 15, yPosition);
      yPosition += 10;
      const plansHeaders = ["Nimi", "Tyyppi", "Tila", "Hinta", "Aloitusvuosi"];
      const plansTableData = plansData.map((plan) => [
        plan.name,
        plan.plan_type?.name || "",
        plan.status?.name || "",
        plan.price ? `${plan.price}€` : "",
        plan.start_year || "",
      ]);

      doc.autoTable({
        head: [plansHeaders],
        body: plansTableData,
        startY: yPosition,
        ...tableStyles,
      });
      yPosition = doc.lastAutoTable.finalY + 15;
    }

    // Maintenance History Table
    if (historyData.length > 0) {
      doc.setFontSize(16);
      doc.text("Toimenpidehistoria", 15, yPosition);
      yPosition += 10;
      const historyHeaders = ["Toimenpide", "Vuosi", "Kustannus (€)"];
      const historyTableData = historyData.map((history) => [
        history.name || "",
        history.performed_on,
        history.cost || "",
      ]);

      doc.autoTable({
        head: [historyHeaders],
        body: historyTableData,
        startY: yPosition,
        ...tableStyles,
      });
      yPosition = doc.lastAutoTable.finalY + 15;
    }

    // Recurring Maintenance History Table
// Recurring Maintenance History Table
if (recurringHistoryData.length > 0) {
  doc.setFontSize(16);
  doc.text("Huoltohistoria (viime vuosi)", 15, yPosition);
  yPosition += 10;
  
  // Add 'Kuvaus' column to the headers
  const recurringHistoryHeaders = ["Toimenpide", "Päivämäärä", "Kuvaus"];
  
  // Map the recurring history data to include 'description'
  const recurringHistoryTableData = recurringHistoryData.map((rec) => [
    rec.element || "",
    formatDateToFinnish(rec.maintenance_date),
    rec.description || "", // Add the 'description' field here
  ]);

  doc.autoTable({
    head: [recurringHistoryHeaders],
    body: recurringHistoryTableData,
    startY: yPosition,
    ...tableStyles,
  });

  yPosition = doc.lastAutoTable.finalY + 15;
}


    // Upcoming Maintenance Table
    if (upcomingMaintenanceData.length > 0) {
      doc.setFontSize(16);
      doc.text("Tulevat toistuvat huollot (5 vuotta)", 15, yPosition);
      yPosition += 10;
      const upcomingHeaders = ["Rakennusosa", "Ajoitettu Päivämäärä"];
      const upcomingTableData = upcomingMaintenanceData.map((rec) => [
        rec.element || "",
        formatDateToFinnish(rec.next_maintenance_date),
      ]);

      doc.autoTable({
        head: [upcomingHeaders],
        body: upcomingTableData,
        startY: yPosition,
        ...tableStyles,
      });
      yPosition = doc.lastAutoTable.finalY + 15;
    }

    // Extra Information Section
    if (extraInfo) {
      doc.setFontSize(16);
      doc.text("Lisätiedot", 15, yPosition);
      yPosition += 10;
      doc.setFontSize(12);
      doc.text(extraInfo, 15, yPosition, { maxWidth: 180 });
    }

    // Save the PDF
    doc.save(`${asoyDetails.name}_kpts.pdf`);

    return "KPTS generated successfully";
  } catch (error) {
    console.error("Error generating KPTS:", error);
    throw error;
  }
};
