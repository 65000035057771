// routes.js
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import AuthProvider, { useAuth } from "./Authentication"; // Import AuthProvider and useAuth
import Home from "../pages/Home";
import Reports from "../pages/Reports";
import Login from "../pages/Login";
import AsoyDetails from "../pages/AsoyDetails";
import UserManagement from "../pages/UserManagement";
import BuildingManager from "../pages/BuildingManager";
import TeamManagement from "../pages/TeamManagement";
import AsoyManagement from "../pages/AsoyManagement";
import AsoyProvider from "../AsoyProvider";

const ProtectedLayout = React.memo(() => {
  const { token } = useAuth();
  const isAuthenticated = React.useMemo(() => !!token, [token]);

  return React.useMemo(() => {
    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    }
    return <Outlet />;
  }, [isAuthenticated]);
});

const AdminLayout = React.memo(() => {
  const { decodedToken } = useAuth();
  const isAdmin = React.useMemo(
    () => decodedToken && decodedToken.role === 1,
    [decodedToken]
  );

  return React.useMemo(() => {
    if (!isAdmin) {
      return <Navigate to="/" replace />;
    }
    return <Outlet />;
  }, [isAdmin]);
});

ProtectedLayout.displayName = 'ProtectedLayout';
AdminLayout.displayName = 'AdminLayout';

// Define and export the router
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <AuthProvider>
          <Outlet />
        </AuthProvider>
      }
    >
      <Route path="/login" element={<Login />} />
      <Route element={<ProtectedLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="raportit" element={<Reports />} />
        <Route
          path="raportit/:asoyId"
          element={
            <AsoyProvider>
              <AsoyDetails />
            </AsoyProvider>
          }
        />
        <Route element={<AdminLayout />}>
          <Route path="user-management" element={<UserManagement />} />
          {/*
          <Route
            path="building-manager-management"
            element={
              <AsoyProvider>
                <BuildingManager />
              </AsoyProvider>
            }
          />
          */}
          <Route
            path="team-management"
            element={
              <AsoyProvider>
                <TeamManagement />
              </AsoyProvider>
            }
          />
          <Route
            path="asoy-management"
            element={
              <AsoyProvider>
                <AsoyManagement />
              </AsoyProvider>
            }
          />
        </Route>
      </Route>
    </Route>
  )
);

export default router;
