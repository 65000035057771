import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";
import theme from "../styles/theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";

const CustomPieChart = ({ data, navigate }) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Chart radius and center adjustments
  const chartOuterRadius = isSmallScreen ? 60 : 90;
  const chartInnerRadius = isSmallScreen ? 30 : 45;
  const chartCenterX = isSmallScreen ? 80 : 140; // Move chart to the left to make room for legend

  // Handle slice click to navigate
  const handleSliceClick = (series, dataPoint) => {
    if (dataPoint?.id) {
      navigate(`/raportit/${dataPoint.id}`);
    } else {
      console.error("No 'id' found in slice data:", dataPoint);
    }
  };

  const isChartDataValid = data && data.length > 0;

  // Example color palette
  const colors = ["#D0E8FF", "#DBAEFF", "#FBE38E", "#FED0EE", "#A9F4D0"];

  return isChartDataValid ? (
    <Box
      sx={{
        position: "relative",
        mt: 2, // Top margin
      }}
    >
      <PieChart
        series={[
          {
            data,
            innerRadius: chartInnerRadius,
            outerRadius: chartOuterRadius,
            cornerRadius: 5,
            paddingAngle: 5,
            cx: 180, // Shift chart left
          },
        ]}
        width={isSmallScreen ? 350 : 650} // Enough width for chart + legend
        height={300}
        onClick={handleSliceClick}
        colors={colors}
        slotProps={{
          legend: {
            display: true,
            direction: "column",
            position: {
              vertical: "middle",
              horizontal: "right",
            },
            style: {
              padding: "16px",
              fontSize: "1rem",
              marginLeft: "40px",
            },
          },
        }}
      />
    </Box>
  ) : null;
};

export default CustomPieChart;
