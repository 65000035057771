import React, {
  useState,
  useEffect,
  useCallback,
  lazy,
  Suspense
} from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Grid,
  Skeleton,
  useMediaQuery,
  Snackbar,
  Alert
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrintIcon from "@mui/icons-material/Print";
import { useParams, useNavigate } from "react-router-dom";
import AsoyNav from "../navigation/AsoyNav";
import theme from "../styles/theme";
import MainLayout from "../MainLayout";
import { useCache } from "../CacheProvider";
import axios from "axios";
import { generateKPTS } from "../dialogs/CreateKPTS";
import KPTSDialog from "../dialogs/KPTSDialog";
import { useAuth } from "../auth/Authentication";

const backendUrl = process.env.REACT_APP_API_URL;

// Lazy-load each tab component
const StructuralElements = lazy(() => import("../datatables/StructuralElements"));
const Maintenances = lazy(() => import("../datatables/Maintenances"));
const Inspections = lazy(() => import("../datatables/MaintenanceHistory"));
const Plans = lazy(() => import("../datatables/Plans"));

function AsoyDetails() {
  const { asoyId } = useParams();
  const [asoyDetails, setAsoyDetails] = useState(null);
  const { get, set } = useCache();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [dialogOpen, setDialogOpen] = useState(false);
  const { token } = useAuth(); // token from auth
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!asoyId) return;

    const fetchAsoyDetails = async () => {
      try {
        // Try to get from cache first
        const cachedData = get(`asoy_${asoyId}`);
        const cachedBasicInfo = get(`basic_asoy_${asoyId}`);

        if (cachedData && cachedBasicInfo) {
          setAsoyDetails({ ...cachedData, ...cachedBasicInfo });
          return;
        }

        // If not in cache, fetch from API
        const [data, basicInfo] = await Promise.all([
          axios.get(`${backendUrl}/asoy/${asoyId}/`),
          axios.get(`${backendUrl}/basic-asoy/${asoyId}/`)
        ]);

        const asoyData = data.data;
        const basicAsoyInfo = basicInfo.data;
        setAsoyDetails({ ...asoyData, ...basicAsoyInfo });

        // Cache the results
        set(`asoy_${asoyId}`, asoyData);
        set(`basic_asoy_${asoyId}`, basicAsoyInfo);
      } catch (error) {
        console.error("Error fetching asoy details: ", error);
      }
    };

    fetchAsoyDetails();
  }, [asoyId, get, set]);

  const handlePrintKPTS = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleGenerateKPTS = async (extraInfo) => {
    try {
      await generateKPTS(asoyId, token, extraInfo);
      setDialogOpen(false);
      setErrorMessage("KPTS generated successfully");
    } catch (error) {
      console.error("Failed to generate KPTS:", error);
      setErrorMessage(error.message || "Failed to generate KPTS. Please try again.");
    }
  };

  const renderAsoyInfo = (label, value) => (
    <Typography variant="body2" component="div">
      <strong>{label}:</strong> {value || <Skeleton width={100} />}
    </Typography>
  );

  return (
    <MainLayout>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          sx={{ mb: 2 }}
        >
          Takaisin
        </Button>

        <Card elevation={3} sx={{ mb: 3, backgroundColor: "#F8F2ED" }}>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={6}>
                <Typography
                  variant={isSmallScreen ? "h6" : "h5"}
                  component="h1"
                  gutterBottom
                >
                  {asoyDetails?.name || <Skeleton width={200} />}
                </Typography>
                {renderAsoyInfo(
                  "Osoite",
                  asoyDetails &&
                    `${asoyDetails.address_road}, ${asoyDetails.address_postal_code} ${asoyDetails.address_city}`
                )}
                {renderAsoyInfo("Y-tunnus", asoyDetails?.business_id)}
                {renderAsoyInfo(
                  "Isännöitsijä",
                  asoyDetails?.building_manager?.name
                )}
              </Grid>
              <Grid item xs={12} sm={6} container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<PrintIcon />}
                  onClick={handlePrintKPTS}
                  sx={{ mt: { xs: 2, sm: 0 } }}
                >
                  Tulosta KPTS
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Wrap each lazy-loaded tab in Suspense */}
        <AsoyNav
          tabs={React.useMemo(
            () => [
              {
                label: "Rakenneosat",
                panel: (
                  <Suspense fallback={<Skeleton />}>
                    <StructuralElements asoyId={asoyId} />
                  </Suspense>
                ),
              },
              {
                label: "Huollot",
                panel: (
                  <Suspense fallback={<Skeleton />}>
                    <Maintenances asoyId={asoyId} />
                  </Suspense>
                ),
              },
              {
                label: "Toimenpidehistoria",
                panel: (
                  <Suspense fallback={<Skeleton />}>
                    <Inspections asoyId={asoyId} />
                  </Suspense>
                ),
              },
              {
                label: "Suunnitelmat",
                panel: (
                  <Suspense fallback={<Skeleton />}>
                    <Plans asoyId={asoyId} />
                  </Suspense>
                ),
              },
            ],
            [asoyId]
          )}
        />

        <KPTSDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          onGenerate={handleGenerateKPTS}
        />

        {/* Show any success/error message as a Snackbar (if desired) */}
        {errorMessage && (
          <Snackbar
            open={Boolean(errorMessage)}
            autoHideDuration={4000}
            onClose={() => setErrorMessage("")}
          >
            <Alert severity="info" onClose={() => setErrorMessage("")}>
              {errorMessage}
            </Alert>
          </Snackbar>
        )}
      </Box>
    </MainLayout>
  );
}

export default AsoyDetails;
