import React, { useState, useContext } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
} from "@mui/material";
import MainLayout from "../MainLayout";
import CustomTable from "../styles/CustomTable";
import CreateAsoy from "../dialogs/CreateAsoy";
import AsoyContext from "../AsoyContext";
import axios from "axios";
const backendUrl = process.env.REACT_APP_API_URL;

const AsoyManagement = () => {
  const { filteredData, refreshData } = useContext(AsoyContext);
  const [createAsoyOpen, setCreateAsoyOpen] = useState(false);
  const [currentEdit, setCurrentEdit] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    name: "",
    business_id: "",
    address_road: "",
    address_number: "",
    address_postal_code: "",
    address_city: "",
    total_shares: "",
    total_sqr_m: "",
  });

  const handleEditOpen = (asoy) => {
    setCurrentEdit({
      ...asoy,
      address_road: asoy.address_road,
      address_number: asoy.address_number,
      address_postal_code: asoy.address_postal_code,
      address_city: asoy.address_city,
    });
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setFieldErrors({});
  };

  const handleEditSave = async () => {
    const errors = {};
    if (!currentEdit.name) errors.name = "Kenttä vaaditaan";
    if (!currentEdit.business_id) errors.business_id = "Kenttä vaaditaan";
    if (!currentEdit.address_road) errors.address_road = "Kenttä vaaditaan";
    if (!currentEdit.address_number) errors.address_number = "Kenttä vaaditaan";
    if (!currentEdit.address_postal_code)
      errors.address_postal_code = "Kenttä vaaditaan";
    if (!currentEdit.address_city) errors.address_city = "Kenttä vaaditaan";
    if (!currentEdit.total_shares) errors.total_shares = "Kenttä vaaditaan";
    if (!currentEdit.total_sqr_m) errors.total_sqr_m = "Kenttä vaaditaan";
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
    try {
      const response = await axios.patch(
        `${backendUrl}/asoy/${currentEdit.id}/update-info/`,
        currentEdit
      );
      if (response.status === 200) {
        console.log("Asoy updated successfully");
        refreshData(); // Refresh the table data after save
        handleEditClose(); // Close the dialog after save
      }
    } catch (error) {
      console.error("Error updating Asoy:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${backendUrl}/asoy/${id}/`);
      if (response.status === 204) {
        console.log("Asoy deleted successfully");
        refreshData();
        setEditOpen(false);
      }
    } catch (error) {
      console.error("Error deleting Asoy:", error);
    }
    setFieldErrors({});
  };

  const columns = [
    { id: "name", label: "Taloyhtiö" },
    { id: "business_id", label: "Y-tunnus" },
    {
      id: "address",
      label: "Osoite",
      render: (row) =>
        row.address_road &&
        row.address_number &&
        row.address_postal_code &&
        row.address_city
          ? `${row.address_road} ${row.address_number}, ${row.address_postal_code} ${row.address_city}`
          : "",
    },
    { id: "total_shares", label: "Osakkeiden lkm" },
    { id: "total_sqr_m", label: "Neliöt" },
  ];

  // Sort the filteredData in alphabetical order by name
  const sortedData = filteredData.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <MainLayout>
      <h1>Taloyhtiöhallinta</h1>
      <Button
        variant="contained"
        onClick={() => setCreateAsoyOpen(true)}
        sx={{ mb: 2 }}
      >
        Lisää Taloyhtiö
      </Button>
      <CreateAsoy
        open={createAsoyOpen}
        onClose={() => {
          setCreateAsoyOpen(false);
          refreshData();
        }}
      />
      <CustomTable
        columns={columns}
        rows={sortedData}
        onRowClick={handleEditOpen}
      />
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Muokkaa taloyhtiötä</DialogTitle>
        <DialogContent>
          {/* Form fields for editing */}
          <TextField
            required
            autoFocus
            margin="dense"
            label="Nimi"
            type="text"
            fullWidth
            variant="standard"
            value={currentEdit.name || ""}
            onChange={(e) =>
              setCurrentEdit({ ...currentEdit, name: e.target.value })
            }
            error={Boolean(fieldErrors.name)}
            helperText={fieldErrors.name}
          />
          <TextField
            required
            margin="dense"
            label="Y-tunnus"
            type="text"
            fullWidth
            variant="standard"
            value={currentEdit.business_id || ""}
            onChange={(e) =>
              setCurrentEdit({ ...currentEdit, business_id: e.target.value })
            }
            error={Boolean(fieldErrors.business_id)}
            helperText={fieldErrors.business_id}
          />
          {/* Additional fields */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Peruuta</Button>
          <Button onClick={handleEditSave}>Tallenna</Button>
          <Button onClick={() => handleDelete(currentEdit.id)} color="error">
            Poista
          </Button>
        </DialogActions>
      </Dialog>
    </MainLayout>
  );
};

export default AsoyManagement;
