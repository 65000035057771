import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
} from "@mui/material";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_URL;

export default function ChangePasswordDialog({ open, onClose }) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    try {
      await axios.put(`${backendUrl}/change-password/`, {
        current_password: currentPassword,
        new_password: newPassword,
      });
      onClose();
    } catch (error) {
      setError(error.response?.data?.detail || "Failed to change password");
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Vaihda salasana</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField
            label="Nykyinen salasana"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            fullWidth
          />
        </Box>
        <Box mb={2}>
          <TextField
            label="Uusi salasana"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            fullWidth
          />
        </Box>
        {error && <Box color="error.main">{error}</Box>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Peruuta</Button>
        <Button onClick={handleSubmit}>Vaihda</Button>
      </DialogActions>
    </Dialog>
  );
}
