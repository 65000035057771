import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/system";
import AsoyContext from "../AsoyContext";
import axios from "axios";

const backendUrl = process.env.REACT_APP_API_URL;

const CustomCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  "&:hover": {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const AsoyTable = () => {
  const { filteredData, handleSearchChange, loading } = useContext(AsoyContext);
  const [favorites, setFavorites] = useState([]);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);

  React.useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const response = await axios.get(`${backendUrl}/favorites/`);
        setFavorites(response.data); // Store the entire favorite objects
      } catch (error) {
        console.error("Error fetching favorites:", error);
      }
    };
    fetchFavorites();
  }, []);

  const handleFavoriteToggle = async (asoyId) => {
    const favorite = favorites.find((fav) => fav.asoy_id === asoyId);
    try {
      if (favorite) {
        // Remove from favorites
        await axios.delete(`${backendUrl}/favorites/${favorite.id}/`);
        setFavorites(favorites.filter((fav) => fav.asoy_id !== asoyId));
      } else {
        // Add to favorites
        const response = await axios.post(`${backendUrl}/favorites/`, {
          asoy_id: asoyId,
        });
        setFavorites([...favorites, response.data]); // Add the full favorite object
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };

  const filtered = showFavoritesOnly
    ? filteredData.filter((asoy) =>
        favorites.some((fav) => fav.asoy_id === asoy.id)
      )
    : filteredData;

  return (
    <Box>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={handleSearchChange}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={showFavoritesOnly}
            onChange={(e) => setShowFavoritesOnly(e.target.checked)}
          />
        }
        label="Näytä vain suosikit"
      />
      {loading ? (
        <CircularProgress />
      ) : (
        <Stack spacing={2}>
          {filtered.map((asoy) => (
            <Box key={asoy.id} display="flex" alignItems="center">
              <Link
                to={`/raportit/${asoy.id}`}
                style={{ textDecoration: "none", flexGrow: 1 }}
              >
                <CustomCard>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {asoy.name}
                    </Typography>
                  </CardContent>
                </CustomCard>
              </Link>
              <IconButton
                onClick={() => handleFavoriteToggle(asoy.id)}
                color="secondary"
              >
                {favorites.some((fav) => fav.asoy_id === asoy.id) ? (
                  <FavoriteIcon />
                ) : (
                  <FavoriteBorderIcon />
                )}
              </IconButton>
            </Box>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default AsoyTable;
export { CustomCard };
