import React, { useState, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import debounce from "lodash.debounce";
import AsoyContext from "./AsoyContext";
import { useAuth } from "./auth/Authentication";
import { useCache } from "./CacheProvider";

const backendUrl = process.env.REACT_APP_API_URL;

const AsoyProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [asoyData, setAsoyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { token } = useAuth();
  const isAuthenticated = !!token;
  const { get, set, remove } = useCache();

  const fetchData = useCallback(async (searchTerm = "") => {
    if (!isAuthenticated || !token) return;

    try {
      setLoading(true);

      const response = await axios.get(`${backendUrl}/asoy-all/`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { search: searchTerm || undefined },
      });

      const { items } = response.data;

      // Sort results alphabetically before setting
      const sortedItems = items.sort((a, b) =>
        a.name.localeCompare(b.name, "fi", { sensitivity: "base" })
      );

      setAsoyData(sortedItems);

      // Cache the results
      if (!searchTerm) {
        set("asoy-all", sortedItems);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [token, isAuthenticated, set]);

  useEffect(() => {
    const cachedData = get("asoy-all");
    if (cachedData && isAuthenticated) {
      const sortedCachedData = cachedData.sort((a, b) =>
        a.name.localeCompare(b.name, "fi", { sensitivity: "base" })
      );
      setAsoyData(sortedCachedData);
    } else if (isAuthenticated) {
      fetchData("");
    }
  }, [isAuthenticated, get, fetchData]);

  const refreshData = useCallback(() => {
    remove("asoy-all");
    fetchData("");
  }, [remove, fetchData]);

  const debouncedSearch = useMemo(
    () =>
      debounce((term) => {
        fetchData(term);
      }, 300),
    [fetchData]
  );

  const handleSearchChange = useCallback(
    (event) => {
      const term = event.target.value.toLowerCase().trim();
      setSearchQuery(term);

      const cachedData = get("asoy-all");

      if (cachedData) {
        if (!term) {
          setAsoyData(cachedData);
        } else {
          const filtered = cachedData
            .filter((asoy) => asoy.name.toLowerCase().includes(term))
            .sort((a, b) =>
              a.name.localeCompare(b.name, "fi", { sensitivity: "base" })
            );
          setAsoyData(filtered);
        }
      } else {
        debouncedSearch(term);
      }
    },
    [debouncedSearch, get]
  );

  const contextValue = useMemo(
    () => ({
      filteredData: asoyData,
      handleSearchChange,
      refreshData,
      loading,
    }),
    [asoyData, handleSearchChange, refreshData, loading]
  );

  return (
    <AsoyContext.Provider value={contextValue}>{children}</AsoyContext.Provider>
  );
};

export default AsoyProvider;
