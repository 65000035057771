import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Box,
  CssBaseline,
  Drawer,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  AppBar,
  Typography,
  Collapse,
  List,
} from "@mui/material";
import { ReactComponent as CustomHomeIcon } from "../icons/house.svg";
import { ReactComponent as CustomReportIcon } from "../icons/report.svg";
import { ReactComponent as Logo } from "../icons/kontu.svg";
import { ReactComponent as CustomUserManagementIcon } from "../icons/users.svg";
import { ReactComponent as CustomTeamManagementIcon } from "../icons/team.svg";
import { ReactComponent as CustomBuildingManagerIcon } from "../icons/building-manager.svg";
import { ReactComponent as CustomAsOyManagementIcon } from "../icons/city.svg";
import { ReactComponent as ManagementIcon } from "../icons/management.svg";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useAuth } from "../auth/Authentication";
import AccountMenu from "./AccountMenu";

const drawerWidthStatic = (180 * 2) / 3;

function SideNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [managementOpen, setManagementOpen] = React.useState(false);
  const { decodedToken } = useAuth();
  const [selectedPage, setSelectedPage] = React.useState(
    location.pathname.split("/")[1]
  );

  const handleManagementClick = () => {
    setManagementOpen(!managementOpen);
  };

  React.useEffect(() => {
    const currentPage = location.pathname.split("/")[1];
    setSelectedPage(currentPage);

    const managementPages = [
      "user-management",
      "building-manager-management",
      "team-management",
      "asoy-management",
    ];
    if (managementPages.includes(currentPage)) {
      setManagementOpen(true);
    } else {
      setManagementOpen(false);
    }
  }, [location]);

  const drawerContent = (
    <div>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "64px",
        }}
      >
        <Logo
          style={{
            height: "48px",
            width: "auto",
          }}
        />
      </Toolbar>
      <ListItemButton
        onClick={() => {
          navigate("/");
        }}
        sx={{
          backgroundColor: selectedPage === "" ? "#E1D1C2" : "white",
          justifyContent: "center",
          flexDirection: "column",
          paddingY: 1,
          "&:hover": {
            backgroundColor: "#E1D1C2",
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: "auto",
            justifyContent: "center",
            color: "inherit",
          }}
        >
          <CustomHomeIcon style={{ height: "30px", width: "30px" }} />
        </ListItemIcon>
        <ListItemText
          primary="Etusivu"
          sx={{
            fontSize: "12px",
            textAlign: "center",
          }}
        />
      </ListItemButton>
      <ListItemButton
        onClick={() => {
          navigate("/raportit");
        }}
        sx={{
          backgroundColor: selectedPage === "raportit" ? "#E1D1C2" : "white",
          justifyContent: "center",
          flexDirection: "column",
          paddingY: 1,
          "&:hover": {
            backgroundColor: "#E1D1C2",
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: "auto",
            justifyContent: "center",
            color: "inherit",
          }}
        >
          <CustomReportIcon style={{ height: "30px", width: "30px" }} />
        </ListItemIcon>
        <ListItemText
          primary="Raportit"
          sx={{
            fontSize: "12px",
            textAlign: "center",
          }}
        />
      </ListItemButton>

      {decodedToken && decodedToken.role === 1 && (
        <div>
          <ListItemButton
            onClick={handleManagementClick}
            sx={{
              justifyContent: "center",
              flexDirection: "column",
              paddingY: 1,
              "&:hover": {
                backgroundColor: "#E1D1C2",
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "auto",
                justifyContent: "center",
                color: "inherit",
              }}
            >
              <ManagementIcon style={{ height: "30px", width: "30px" }} />
            </ListItemIcon>
            <ListItemText
              primary="Hallinta"
              sx={{
                fontSize: "12px",
                textAlign: "center",
              }}
            />
            {managementOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={managementOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {[
                {
                  name: "Käyttäjät",
                  path: "/user-management",
                  icon: <CustomUserManagementIcon />,
                },
//               {
//                 name: "Isännöitsijät",
//                 path: "/building-manager-management",
//                 icon: <CustomBuildingManagerIcon />,
//               },
                {
                  name: "Tiimit",
                  path: "/team-management",
                  icon: <CustomTeamManagementIcon />,
                },
                {
                  name: "Taloyhtiöt",
                  path: "/asoy-management",
                  icon: <CustomAsOyManagementIcon />,
                },
              ].map((item) => (
                <ListItemButton
                  key={item.path}
                  onClick={() => navigate(item.path)}
                  sx={{
                    backgroundColor:
                      selectedPage === item.path.split("/")[1]
                        ? "#E1D1C2"
                        : "white",
                    justifyContent: "center",
                    flexDirection: "column",
                    paddingY: 1,
                    "&:hover": { backgroundColor: "#E1D1C2" },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "auto",
                      justifyContent: "center",
                      color: "inherit",
                    }}
                  >
                    {React.cloneElement(item.icon, {
                      style: { height: "30px", width: "30px" },
                    })}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </div>
      )}
    </div>
  );

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidthStatic}px)`,
          ml: `${drawerWidthStatic}px`,
          bgcolor: "background.default",
          transition: "width 0.3s, margin 0.3s",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography variant="h6" noWrap>
            TaloKunto
          </Typography>
          <Box>
            <AccountMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidthStatic,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidthStatic,
            boxSizing: "border-box",
            overflowX: "hidden",
          },
        }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
}

export default SideNavbar;
