import React, { useState } from "react";
import { jwtDecode } from "jwt-decode"; // For newer versions of jwt-decode
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/Authentication";
import ChangePasswordDialog from "../dialogs/ChangePassword"; // Import ChangePasswordDialog

function AccountMenu() {
  const navigate = useNavigate();
  const { token, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false); // State for Change Password Dialog
  const open = Boolean(anchorEl);

  // Decode the JWT token to extract user details
  let userDetails = { firstName: "", lastName: "" };
  if (token) {
    const decodedToken = jwtDecode(token);
    userDetails = decodedToken.firstName && decodedToken.lastName
      ? {
          firstName: decodedToken.firstName,
          lastName: decodedToken.lastName,
        }
      : {
          firstName: decodedToken.sub,
          lastName: "",
        };
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate("/login", { replace: true });
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Käyttäjäasetukset">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              sx={{ width: 32, height: 32, bgcolor: "#F8F2ED", mr: 1 }}
            ></Avatar>
            <Typography>{`${userDetails.firstName} ${userDetails.lastName}`}</Typography>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {/* Change Password Option */}
        <MenuItem
          onClick={() => {
            setChangePasswordOpen(true);
          }}
        >
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          Vaihda salasana
        </MenuItem>

        {/* Logout Option */}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Kirjaudu ulos
        </MenuItem>
      </Menu>

      {/* Change Password Dialog */}
      <ChangePasswordDialog
        open={changePasswordOpen}
        onClose={() => setChangePasswordOpen(false)}
      />
    </React.Fragment>
  );
}

export default AccountMenu;
