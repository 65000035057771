import React, { createContext, useReducer, useContext, useEffect } from 'react';

// Define the context
const CacheContext = createContext();

// Define cache entry type with timestamp
const createCacheEntry = (value) => ({
    value,
    timestamp: Date.now()
});

// Define the reducer
const cacheReducer = (state, action) => {
    switch (action.type) {
        case 'SET':
            return {
                ...state,
                [action.key]: createCacheEntry(action.value)
            };
        case 'REMOVE':
            const newState = { ...state };
            delete newState[action.key];
            return newState;
        case 'CLEAR_ALL':
            return {};
        default:
            return state;
    }
};

// Cache expiration time (5 minutes)
const CACHE_EXPIRATION = 5 * 60 * 1000;

// Define the provider
const CacheProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cacheReducer, {});

    // Functions to interact with the cache
    const set = (key, value) => {
        if (key && value !== undefined) {
            dispatch({ type: 'SET', key, value });
        }
    };

    const get = (key) => {
        const entry = state[key];
        if (!entry) return null;

        // Check if cache entry has expired
        if (Date.now() - entry.timestamp > CACHE_EXPIRATION) {
            dispatch({ type: 'REMOVE', key });
            return null;
        }

        return entry.value;
    };

    const remove = (key) => {
        if (key) {
            dispatch({ type: 'REMOVE', key });
        }
    };

    useEffect(() => {
        // Clear expired cache entries every minute
        const cleanupInterval = setInterval(() => {
            const now = Date.now();
            Object.entries(state).forEach(([key, entry]) => {
                if (now - entry.timestamp > CACHE_EXPIRATION) {
                    dispatch({ type: 'REMOVE', key });
                }
            });
        }, 60 * 1000); // Check every minute

        return () => clearInterval(cleanupInterval);
    }, [state]);

    return (
        <CacheContext.Provider value={{ set, get, remove }}>
            {children}
        </CacheContext.Provider>
    );
};

// Define a hook for easy access to the cache
export const useCache = () => {
    const context = useContext(CacheContext);
    if (context === undefined) {
        throw new Error('useCache must be used within a CacheProvider');
    }
    return context;
};

export default CacheProvider;
