import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Typography, CircularProgress } from "@mui/material";
import CustomTable from "../styles/CustomTable.js";
import CustomPieChart from "../utils/CustomPieChart.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/Authentication.js";
import { useCache } from "../CacheProvider.js";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../styles/theme.js";

const backendUrl = process.env.REACT_APP_API_URL;

const MaintenancesAll = () => {
  const [rows, setRows] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { token } = useAuth();
  const { get, set } = useCache();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const isAuthenticated = Boolean(token);

  useEffect(() => {
    if (!isAuthenticated || !token) {
      setLoading(false);
      return;
    }

    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        if (!isAuthenticated || !token) return;

        const cacheTimestamp = get("recurring_maintenance_timestamp");
        const cacheData = get("recurring_maintenance");

        // Use cache if data is recent (valid for 5 minutes)
        const isCacheValid = cacheTimestamp && (Date.now() - cacheTimestamp < 5 * 60 * 1000);
        if (isCacheValid && cacheData) {
          handleData(cacheData);
          setLoading(false);
          return;
        }

        setLoading(true); // Show loading spinner during fetch

        const response = await axios.get(`${backendUrl}/asoy/maintenance/recurring/all/`, {
          headers: { Authorization: `Bearer ${token}` },
          signal: abortController.signal,
          timeout: 10000,
        });

        // Ensure data is in expected format
        if (!Array.isArray(response.data)) {
          throw new Error("Invalid response format from the server.");
        }

        handleData(response.data);
        set("recurring_maintenance", response.data);
        set("recurring_maintenance_timestamp", Date.now());
      } catch (err) {
        console.error("Error fetching maintenance data:", err);
        // Return empty table if fetch fails
        setRows([]);
        setPieChartData([]);
      } finally {
        setLoading(false);
      }
    };

    // Initial fetch
    fetchData();

    return () => {
      abortController.abort();
    };
  }, [isAuthenticated, token, get, set]);

  const formatDate = (date) => {
    if (!date) return "Ei tietoa";
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  };
  
  const handleData = (dataArray) => {
    let transformedRows = [];
    try {
      transformedRows = dataArray.map((item) => ({
        element: item.element || "Ei määritelty",
        nextMaintenance: formatDate(item.next_maintenance_date), // Format the date here
        status: item.status || "Odottaa",
        asoy: item.asoyName || "Ei tietoa",
        elementId: item.elementId,
        asoyId: item.asoyId,
      }));
  
      setRows(transformedRows);
    } catch (error) {
      console.error("Error transforming data:", error);
      setRows([]); // Empty rows if transformation fails
    }

    // Pie chart data processing with consistent colors
    const asoyCounts = dataArray.reduce((counts, item) => {
      counts[item.asoyName] = (counts[item.asoyName] || 0) + 1;
      return counts;
    }, {});

    const colors = generateColors(Object.keys(asoyCounts));

    const pieData = Object.entries(asoyCounts).map(([label, value], index) => ({
      id: label,
      label,
      value,
      color: colors[index],
    }));

    setPieChartData(pieData);
  };

  const generateColors = (keys) => {
    // Generate consistent colors based on the key index
    const baseColors = ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40"];
    return keys.map((_, index) => baseColors[index % baseColors.length]);
  };

  const handleRowClick = (row) => {
    if (row && row.asoyId) {
      navigate(`/raportit/${row.asoyId}/`);
    }
  };

  const getStatusColor = (status) => {
    if (status === "Myöhässä") return "#ff0000";
    if (status === "Odottaa") return "#ffa500";
    return "#00ff00"; // Default color for other statuses
  };

  const columns = [
    {
      id: "element",
      label: "Rakenneosa",
      style: { whiteSpace: "normal", wordWrap: "break-word", minWidth: "300px" },
    },
    {
      id: "nextMaintenance",
      label: "Seuraava huolto",
      style: { minWidth: "150px" },
    },
    {
      id: "status",
      label: "Tila",
      style: (value) => ({
        color: getStatusColor(value),
        fontWeight: "bold",
      }),
    },
    {
      id: "asoy",
      label: "Asoy",
      style: { minWidth: "150px" },
    },
  ];

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="h5" sx={{ marginLeft: isSmallScreen ? "0px" : "20px", mb:1 }}>
        Tulevan vuoden huollot
      </Typography>

      {pieChartData.length > 0 && (
        <Box sx={{ mt: 4, mb: 3 }}> 
          <CustomPieChart
            data={pieChartData}
            navigate={navigate}
            legendOptions={{
              position: "right",
              labels: {
                fontSize: "1rem",
                marginLeft: "60px"
              }
            }}
          />
        </Box>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
          marginLeft: isSmallScreen ? "-60px" : "0px",
          width: isSmallScreen ? "100vw" : "auto",
        }}
      >
        <CustomTable
          columns={columns}
          rows={rows} // Empty rows will render a blank table
          onRowClick={handleRowClick}
          hoverBackgroundColor={"#f5f5f5"}
        />
      </Box>
    </Box>
  );
};

export default MaintenancesAll;
