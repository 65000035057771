import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Autocomplete,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useCache } from "../CacheProvider";

const backendUrl = process.env.REACT_APP_API_URL;

export default function EditUser({ open, onClose, user }) {
  /**
   * user: the object you want to edit, e.g.
   * {
   *   id: 123,
   *   username: "oldUsername",
   *   email: "oldEmail@example.com",
   *   first_name: "Old",
   *   last_name: "Name",
   *   role: "admin", // or role_list_id
   *   team_id: 1
   * }
   */
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");          // Optional
  const [confirmPassword, setConfirmPassword] = useState(""); // Optional
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [roles, setRoles] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const [fieldErrors, setFieldErrors] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    role: "",
    team: "",
  });

  const { get, set, remove } = useCache();

  const fetchRoles = useCallback(async () => {
    const cachedData = get("roles");
    if (cachedData) {
      setRoles(cachedData);
      return;
    }
    try {
      const response = await axios.get(`${backendUrl}/roles/`);
      setRoles(response.data);
      set("roles", response.data);
    } catch (error) {
      console.error("Failed to fetch roles:", error);
    }
  }, [get, set]);

  const fetchTeams = useCallback(async () => {
    const cachedData = get("teams");
    if (cachedData) {
      setTeams(cachedData);
      return;
    }
    try {
      const response = await axios.get(`${backendUrl}/teams/`);
      setTeams(response.data);
      set("teams", response.data);
    } catch (error) {
      console.error("Failed to fetch teams:", error);
    }
  }, [get, set]);

  // Populate form fields when dialog opens
  useEffect(() => {
    if (open && user) {
      setUsername(user.username || "");
      setEmail(user.email || "");
      setFirstName(user.first_name || "");
      setLastName(user.last_name || "");
      setPassword("");
      setConfirmPassword("");

      // If we store only "role name" in user, you might need to map it to actual role object:
      // Example: let matchedRole = roles.find(r => r.name === user.role)
      // or if the user object has role_list_id, you can find it that way:
      // Example: let matchedRole = roles.find(r => r.id === user.role_list_id)
      // For simplicity:
      const roleObj = roles.find((r) => r.name === user.role);
      const teamObj = teams.find((t) => t.id === user.team_id);
      setSelectedRole(roleObj || null);
      setSelectedTeam(teamObj || null);
    }
  }, [open, user, roles, teams]);

  useEffect(() => {
    fetchRoles();
    fetchTeams();
  }, [fetchRoles, fetchTeams]);

  const clearForm = () => {
    setUsername("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setFirstName("");
    setLastName("");
    setSelectedRole(null);
    setSelectedTeam(null);
    setFieldErrors({
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      role: "",
      team: "",
    });
  };

  const handleClose = () => {
    onClose();
    clearForm();
  };

  const handleSubmit = async () => {
    const errors = {};
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!username) errors.username = "Kenttä vaaditaan";
    if (!email) errors.email = "Kenttä vaaditaan";
    else if (!emailRegex.test(email)) {
      errors.email = "Sähköposti ei ole kelvollinen";
    }
    if (!firstName) errors.firstName = "Kenttä vaaditaan";
    if (!lastName) errors.lastName = "Kenttä vaaditaan";
    if (!selectedRole) errors.role = "Kenttä vaaditaan";
    if (!selectedTeam) errors.team = "Kenttä vaaditaan";

    // Optional password validation
    if (password || confirmPassword) {
      if (password.length < 6) {
        errors.password = "Salasanan pitää olla vähintään 6 merkkiä";
      }
      if (password !== confirmPassword) {
        errors.confirmPassword = "Salasanat eivät täsmää";
      }
    }

    if (Object.keys(errors).length) {
      setFieldErrors(errors);
      return;
    }

    const payload = {
      username,
      email,
      first_name: firstName,
      last_name: lastName,
      role_list_id: selectedRole?.id || null,
      team_id: selectedTeam?.id || null,
      // Only include password if user actually wants to change it
      ...(password && { password }),
    };

    try {
      await axios.put(`${backendUrl}/user/${user.id}/`, payload);
      // Remove the users cache to force a refresh
      remove("users");
      handleClose();
    } catch (error) {
      console.error("Error updating user:", error);
      // Handle any backend validation errors if needed
    }
  };

  const handleDelete = async () => {
    try {
      if (user.role === "admin") {
        console.log("Cannot delete user with admin role");
        return;
      }
      await axios.delete(`${backendUrl}/user/${user.id}/`);
      remove("users");
      handleClose();
    } catch (error) {
      console.error("Error deleting User:", error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Muokkaa käyttäjää</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Autocomplete
            id="role-select"
            options={roles}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Valitse rooli"
                error={Boolean(fieldErrors.role)}
                helperText={fieldErrors.role}
                variant="standard"
              />
            )}
            value={selectedRole}
            onChange={(event, newValue) => {
              setSelectedRole(newValue);
            }}
          />
        </Box>

        <Box mb={2}>
          <Autocomplete
            id="team-select"
            options={teams}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Valitse tiimi"
                error={Boolean(fieldErrors.team)}
                helperText={fieldErrors.team}
                variant="standard"
              />
            )}
            value={selectedTeam}
            onChange={(event, newValue) => {
              setSelectedTeam(newValue);
            }}
          />
        </Box>

        <Box mb={2}>
          <TextField
            required
            id="firstName"
            label="Etunimi"
            value={firstName}
            fullWidth
            onChange={(e) => setFirstName(e.target.value)}
            error={Boolean(fieldErrors.firstName)}
            helperText={fieldErrors.firstName}
            variant="standard"
          />
        </Box>

        <Box mb={2}>
          <TextField
            required
            id="lastName"
            label="Sukunimi"
            value={lastName}
            fullWidth
            onChange={(e) => setLastName(e.target.value)}
            error={Boolean(fieldErrors.lastName)}
            helperText={fieldErrors.lastName}
            variant="standard"
          />
        </Box>

        <Box mb={2}>
          <TextField
            required
            id="username"
            label="Käyttäjänimi"
            value={username}
            fullWidth
            onChange={(e) => setUsername(e.target.value)}
            error={Boolean(fieldErrors.username)}
            helperText={fieldErrors.username}
            variant="standard"
          />
        </Box>

        <Box mb={2}>
          <TextField
            required
            id="email"
            label="Sähköposti"
            value={email}
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
            error={Boolean(fieldErrors.email)}
            helperText={fieldErrors.email}
            variant="standard"
          />
        </Box>

        {/* Optional password fields for updating password */}
        <Box mb={2}>
          <TextField
            id="password"
            label="Salasana (jätä tyhjäksi, jos et halua vaihtaa)"
            value={password}
            fullWidth
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            error={Boolean(fieldErrors.password)}
            helperText={fieldErrors.password}
            variant="standard"
          />
        </Box>

        <Box mb={2}>
          <TextField
            id="confirmPassword"
            label="Vahvista salasana"
            value={confirmPassword}
            fullWidth
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={Boolean(fieldErrors.confirmPassword)}
            helperText={fieldErrors.confirmPassword}
            variant="standard"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Peruuta</Button>
        <Button onClick={handleSubmit}>Tallenna</Button>
        {user.role !== "admin" && (
          <Button onClick={handleDelete} color="error">
            Poista
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
