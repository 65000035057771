import React, { useEffect, useState, useCallback } from "react";
import { Button, Box } from "@mui/material";
import axios from "axios";
import MainLayout from "../MainLayout";
import CustomTable from "../styles/CustomTable";
import CreateUser from "../dialogs/CreateUser";
import EditUser from "../dialogs/EditUser";
import AsoyProvider from "../AsoyProvider";
import { useCache } from "../CacheProvider";

const backendUrl = process.env.REACT_APP_API_URL;

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [editOpen, setEditOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [createUserOpen, setCreateUserOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [teams, setTeams] = useState([]);
  const { get, set, remove } = useCache();

  const fetchUsers = useCallback(async () => {
    const cachedData = get("users");
    if (cachedData) {
      setUsers(cachedData);
      return;
    }

    try {
      const response = await axios.get(`${backendUrl}/users/`);
      const transformedUsers = response.data.map((user) => ({
        ...user,
        role: user.role.name,
        team_name: user.team?.name || "Ei tiimiä",
      }));
      setUsers(transformedUsers);
      set("users", transformedUsers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }, [get, set]);

  const fetchRoles = useCallback(async () => {
    const cachedData = get("roles");
    if (cachedData) {
      setRoles(cachedData);
      return;
    }

    try {
      const response = await axios.get(`${backendUrl}/roles/`);
      setRoles(response.data);
      set("roles", response.data);
    } catch (error) {
      console.error("Failed to fetch roles:", error);
    }
  }, [get, set]);

  const fetchTeams = useCallback(async () => {
    const cachedData = get("teams");
    if (cachedData) {
      setTeams(cachedData);
      return;
    }

    try {
      const response = await axios.get(`${backendUrl}/teams/`);
      setTeams(response.data);
      set("teams", response.data);
    } catch (error) {
      console.error("Failed to fetch teams:", error);
    }
  }, [get, set]);

  useEffect(() => {
    fetchUsers();
    fetchRoles();
    fetchTeams();
  }, [fetchUsers, fetchRoles, fetchTeams]);

  const refreshUsers = async () => {
    remove("users");
    fetchUsers();
  };

  const handleEditOpen = (user) => {
    if (!user) {
      console.error("No user selected for editing");
      return;
    }
    setUserToEdit(user);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setUserToEdit(null);
  };

  const handleCreateUser = async () => {
    setCreateUserOpen(false);
    refreshUsers();
    fetchRoles();
  };

  const columns = [
    { id: "username", label: "Käyttäjänimi" },
    { id: "email", label: "Sähköposti" },
    { id: "role", label: "Rooli" },
    {
      id: "team",
      label: "Tiimi",
      render: (row) => {
        const team = teams.find((t) => t.id === row.team_id);
        return team ? team.name : "Ei tiimiä";
      },
    },
  ];

  return (
    <AsoyProvider>
      <MainLayout>
        <h1>Käyttäjähallinta</h1>
        <Button
          variant="contained"
          onClick={() => setCreateUserOpen(true)}
          sx={{ mb: 2 }}
        >
          Luo uusi käyttäjä
        </Button>
        <CreateUser open={createUserOpen} onClose={handleCreateUser} />
        <CustomTable
          columns={columns}
          rows={users}
          onRowClick={handleEditOpen}
        />
        {userToEdit && (
          <EditUser
            open={editOpen}
            onClose={handleEditClose}
            user={userToEdit}
          />
        )}
      </MainLayout>
    </AsoyProvider>
  );
};

export default UserManagement;
