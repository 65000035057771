import { createTheme } from '@mui/material/styles';

// Create a base theme
const baseTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: '#E1D1C2',         // Warm beige
      light: '#F8F2ED',       // Soft cream
      dark: '#3B3F63',        // Deep navy
      contrastText: '#000000', // Black text
    },
    secondary: {
      main: '#CA9667',        // Warm tan
      light: '#A0A2B3',       // Muted grey-blue
      dark: '#092341',        // Deep slate blue
      contrastText: '#000000', // Black text
    },
    action: {
      hover: '#F8F2ED',       // Soft cream for hover
      click: '#E1D1C2',       // Warm beige for click
    },
    // Additional Colors
    success: {
      main: '#7A9E7E',        // Muted sage green
      light: '#B8D8B4',       // Soft pastel green
      dark: '#4C7A4D',        // Deep forest green
      contrastText: '#000000', // Black text
    },
    error: {
      main: '#D87C7C',        // Warm terracotta red
      light: '#E9A3A3',       // Soft rose pink
      dark: '#A05252',        // Deep clay red
      contrastText: '#000000', // Black text
    },
    warning: {
      main: '#E6B464',        // Warm goldenrod
      light: '#F8D49D',       // Light honey yellow
      dark: '#B3822C',        // Deep amber
      contrastText: '#000000', // Black text
    },
    info: {
      main: '#7A8CA9',        // Muted blue-grey
      light: '#A0B3D1',       // Soft sky blue
      dark: '#52657D',        // Deep denim blue
      contrastText: '#000000', // Black text
    },
    grey: {
      50: '#F9F9F9',          // Almost white
      100: '#E1E1E1',         // Light grey
      200: '#C7C7C7',         // Muted grey
      300: '#AFAFAF',         // Mid-grey
      400: '#8F8F8F',         // Darker grey
      500: '#6F6F6F',         // Deep grey
      600: '#4F4F4F',         // Charcoal
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: '100%',
          [baseTheme.breakpoints.up('md')]: {
            width: '70%',
          },
        },
        root: {
          '& .MuiTextField-root': {
            '& .MuiTextField-standard': {
              variant: 'standard',
            },
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          '& .MuiButton-root': {
            backgroundColor: 'white', 
            color: '#3B3F63',
            '&:hover': {
              backgroundColor: '#F8F2ED',
            },
          },
        },
      },
    },
  },
});

export default theme;
